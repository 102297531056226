<template>
  <v-container>
    <v-card flat>
      <v-form v-model="valid">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="staffMember.first_name" :disabled="loading" label="First Name"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="staffMember.surname" :disabled="loading" label="Surname"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="staffMember.suburb" :disabled="loading" label="Suburb"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="staffMember.staff_statuses_id"
              :disabled="loading"
              :items="lists.status"
              :menu-props="{ maxHeight: '400' }"
              label="Status"
              item-text="title"
              item-value="id"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="staffMember.staff_types_id"
              :disabled="loading"
              :items="lists.types"
              :menu-props="{ maxHeight: '400' }"
              label="Type"
              item-text="title"
              item-value="id"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="staffMember.card_expire_from"
              :disabled="loading"
              label="Card Expire From"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="staffMember.card_expire_to"
              :disabled="loading"
              label="Card Expire To"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn @click="searchAction" :disabled="loading" class="primary button--search">Search</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<style scoped>
.col-md-6,
.col {
  padding-top: 0;
  padding-bottom: 0;
}
.button--search {
  margin-left: 0;
  margin-bottom: 20px;
}
</style>
<script>
export default {
  name: 'StaffMemberSearchForm',
  methods: {
    searchAction: function () {
      this.$emit('clicked-search-button', this.staffMember);
    }
  },
  props: ['meta'],
  computed: {
    /*
     * We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    lists: function () {
      if (this.meta && this.meta.lists) {
        return this.meta.lists;
      }
      return {
        status: [],
        types: []
      };
    }
  },
  data: () => ({
    loading: false,
    valid: false,
    staffMember: {
      active: true
    }
  })
};
</script>
